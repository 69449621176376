.modal__content {
  padding: 20px 0;

  .modal__content__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;

    @media only screen and (max-width: 1100px) {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media only screen and (max-width: 950px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .modal__content__cart {
      border-top: 4px solid lawngreen;
      border-radius: 7px;
      box-shadow: 2px 2px 10px rgb(184, 183, 181);
      position: relative;

      .modal__content__cart__header {
        padding: 10px 15px;
        border-bottom: 1px solid rgb(196, 195, 193);
        font-size: 14px;
        font-weight: bold;
      }

      .modal__content__cart__content {
        padding: 15px;
        font-size: 13px;

        .modal__content__content__flex {
          display: flex;
          justify-content: space-between;

          .order_operator_style {
            font-size: 14px;
            font-weight: bold;
          }
        }

        .order_date_now {
          color: red;
          font-weight: bold;
        }

        .stream_price_style {
          margin-top: 10px;
          font-size: 14px;
          font-weight: bold;
        }
        .order_id_style {
          margin: 2px 0;
          font-size: 14px;
          font-weight: bold;
        }

        .order_dress_up_button {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 7px 0;
          background-color: chartreuse;
          border-radius: 5px;
          margin-top: 10px;
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
  }
}

.modal__content__null {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80%;
}

.order__page__all {
  width: 100%;
  height: 90vh;
}

.modal__content__cart__header__loading_parent {
  width: 50%;
  min-height: 20px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
  margin: 3px 5px;
}

.modal__content__cart__header__loading_parent2 {
  width: 30%;
  min-height: 20px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
  margin: 3px 5px;
}

.modal__content__cart__header__loading_parent3 {
  width: 60%;
  min-height: 20px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
  margin: 3px 5px;
}

.modal__content__cart__header__loading_parent4 {
  width: 80%;
  min-height: 20px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
  margin: 3px 5px;
}

.modal__content__cart__header__loading_parent5 {
  width: 90%;
  min-height: 20px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
  margin: 3px 5px;
}

.modal__content__cart__header__loading_parent6 {
  width: 70%;
  min-height: 15px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
  margin: 3px 5px;
}

.modal__content__grid__loading {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  padding: 20px 0;

    @media only screen and (max-width: 1100px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    
      @media only screen and (max-width: 950px) {
        grid-template-columns: 1fr 1fr;
      }
    
      @media only screen and (max-width: 600px) {
        grid-template-columns: 1fr;
      }

  .modal__content__cart__loading {
      border-top: 4px solid lawngreen;
      border-radius: 7px;
      box-shadow: 2px 2px 10px rgb(184, 183, 181);
      position: relative;
  }
}

.modal__content__content__button {
  width: 100%;
  padding: 10px 0;
  background:seagreen;
  margin-top: 7px;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
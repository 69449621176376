.login__page {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 700px) {
    height: auto;
  }

  .login__page__row {
    position: relative;
    display: flex;
    align-items: center;
    height: 300px;
    width: 700px;
    box-shadow: 1px 1px 10px rgb(151, 149, 149);
    padding: 30px;
    border-radius: 8px;
    background: radial-gradient(
      ellipse at bottom left,
      #fff 10%,
      blue 15%,
      #fff 15%,
      #fff 60%,
      #fff 85%,
      red 50%
    );

    @media only screen and (max-width: 700px) {
      flex-direction: column-reverse;
      height: auto;
      width: 100%;
      padding: 0px;
      margin-top: 30px;
    }

    .login__page__box__image {
      width: 50%;
      height: 300px;
      border-radius: 8px;

      @media only screen and (max-width: 700px) {
        height: auto;
        width: auto;
        padding: 0px;
      }

      .login__page__box__image__box {
        width: 300px;
        height: 300px;

        img {
          width: 100%;
        }
      }
    }

    .login__page__box {
      width: 50%;
      height: 300px;
      border-radius: 8px;
      margin-top: 65px;
      @media only screen and (max-width: 700px) {
        width: 90%;
      }

      .login__page__box__form__group {
        margin: 10px 0 20px 0;
        .login__page__box__input {
          padding: 10px;
          width: -webkit-fill-available;
          border: 1px solid #0f3979;
          outline: none;
          border-radius: 8px;
          font-size: 17px !important;
        }
      }
    }
  }
}

.login__page__box__title__row {
  display: flex;
  align-items: center;
  justify-content: end;
  position: absolute;
  top: 20px;
  right: 20px;

  .login__page__box__group__button__ramci {
    border: 1px solid rgb(151, 150, 150);
    border-radius: 6px;
    margin-bottom: 20px;
    background-color: white;

    .login__page__box__button__right {
      border: none;
      background-color: transparent;
      border-radius: 5px 0 0 5px;
      cursor: pointer;
      padding: 5px 10px;
    }

    .login__page__box__button__left {
      border: none;
      background-color: transparent;
      border-radius: 0 5px 5px 0;
      cursor: pointer;
      padding: 5px 10px;
    }

    .active_link {
      background: #0f3979;
      color: white;
    }
  }
}

.login__page__phone__label {
  margin-top: 10px;
  font-size: 10px;
}

.navbar {
  width: 100%;
  height: 100px;
  background: linear-gradient(
    90deg,
    rgba(12, 54, 115, 1) 0%,
    rgba(65, 115, 242, 1) 50%,
    rgba(12, 54, 115, 1) 100%
  );

  @media only screen and (max-width: 780px) {
    height: 75px;
  }

  .navbar__item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;

    .nav__item {
      color: white;
      display: flex;
      align-items: center;

      .nav__item__language {
        font-size: 14px;

        .nav__item__call__center {
          padding: 7px 10px;
          border-radius: 7px;
          background: linear-gradient(
            90deg,
            rgba(12, 54, 115, 1) 0%,
            rgba(65, 115, 242, 1) 50%,
            rgba(12, 54, 115, 1) 100%
          );
          margin-right: 10px;
        }
      }

      .nav__item__location {
        font-size: 14px;
        display: flex;
        align-items: center;

        .nav__item__location__icons {
          margin-right: 5px;
        }

        .nav__item__delivery__info {
          padding: 7px 10px;
          border-radius: 7px;
          background: linear-gradient(
            90deg,
            rgba(12, 54, 115, 1) 0%,
            rgba(65, 115, 242, 1) 50%,
            rgba(12, 54, 115, 1) 100%
          );
          margin-left: 10px;
        }
      }
    }
  }
}

.navbar__item__global {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  color: white;
  align-items: center;

  @media only screen and (max-width: 700px) {
    justify-content: center;
  }

  .nav__item__brand {
    display: flex;
    align-items: center;

    .nav__logo {
      width: 150px;

      img {
        width: 100%;
      }
    }

    .nav__item__category {
      border: 1px solid;
      padding: 7px 60px;
      border-radius: 10px;
      margin-left: 20px;
      cursor: pointer;

      @media only screen and (max-width: 940px) {
        display: none;
      }
    }
  }

  .nav__item__icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;

    @media only screen and (max-width: 780px) {
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(12, 54, 115, 1) 0%,
        rgba(65, 115, 242, 1) 50%,
        rgba(12, 54, 115, 1) 100%
      );
      z-index: 999;
      padding: 15px;
    }

    .nav__item__icon__rigth {
      margin-right: 0px;

      @media only screen and (max-width: 780px) {
        margin-right: 20px;
      }
    }
  }
  .nav__item__search {
    margin-top: 0px;
    width: -webkit-fill-available;

    @media only screen and (max-width: 700px) {
      display: none;
    }

    .nav__item__search__input {
      width: -webkit-fill-available;
      outline: none;
      margin-left: 20px;
      border-radius: 10px;
      border: none;
      padding: 10px 10px;
      font-size: 16px;
      background-color: #6594eb;
      color: #d5caca;
    }
  }
}

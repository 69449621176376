/* Basek styles*/
.skeleton {
  margin: 10px 0;
}
.shimmer-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skewX(-20deg);
  overflow: hidden !important;
}
.shimmer {
  width: 50%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.2);
  animation: loader 2s infinite;
}

@keyframes loader {
  0% {
    transform: translateX(-150%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(150%);
  }
}

.skeleton__order__product {
  overflow: hidden !important;
}

.skeleton__order__product .product__title__loading {
  width: 60%;
  min-height: 50px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.skeleton__order__product .product__order__icon__loading {
  width: 20%;
  min-height: 30px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.skeleton__order__product .product__order__image__loading {
  width: 100%;
  min-height: 350px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.skeleton__order__product .product__order__form__title {
  width: 50%;
  min-height: 30px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.skeleton__order__product .product__order__page__input__loading {
  width: 100%;
  min-height: 60px;
  margin-top: 20px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.product__title__loading__parent {
  width: 60%;
  min-height: 50px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.product__order__icon__loading__parent {
  width: 20%;
  min-height: 30px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.product__order__image__loading__parent {
  width: 100%;
  min-height: 350px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.product__order__form__title__parent {
  width: 50%;
  min-height: 30px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.product__order__page__input__loading__parent {
  width: 100%;
  min-height: 60px;
  margin-top: 20px;
  background-color: #d2dbe2;
  border-radius: 7px;
  position: relative;
}

.product__page__title {
  margin: 30px 0;

  @media only screen and (max-width: 600px) {
    margin: 10px 0 20px 0;
    font-size: 22px;
  }
}

.product__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 15px;
  margin-top: 20px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 1031px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media only screen and (max-width: 780px) {
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (max-width: 568px) {
    grid-template-columns: 1fr 1fr;
  }

  .product__cart {
    width: -webkit-fill-available;
    height: auto;
    position: relative;

    border-radius: 5px;
    box-shadow: 1px 1px 10px rgb(179, 172, 172);
    overflow: hidden;
    position: relative;

    @media only screen and (max-width: 1200px) {
      width: -webkit-fill-available;
    }
    @media only screen and (max-width: 1031px) {
      width: -webkit-fill-available;
    }
    @media only screen and (max-width: 780px) {
      width: -webkit-fill-available;
    }

    .product__cart__love {
      position: absolute;
      top: 10px;
      right: 10px;
      color: red;

      .product__cart__love__icon {
        &:hover {
          fill: red;
        }
      }
    }

    .product__cart__image__ramci {
      width: -webkit-fill-available;
      height: 220px;

      @media only screen and (max-width: 1200px) {
        width: -webkit-fill-available;
      }
      @media only screen and (max-width: 1031px) {
        width: -webkit-fill-available;
      }
      @media only screen and (max-width: 780px) {
        width: -webkit-fill-available;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .product__cart__body {
      padding: 10px;

      .product__cart__title {
        font-size: 15px;
        font-family: monospace;
        display: block;
      }

      .product__cart__price {
        font-size: 14px;
        font-weight: bold;
        display: block;
        margin: 10px 0;

        .product__cart__price__s {
          font-weight: 400;
        }
      }

      .product__cart__order__button {
        padding: 5px 10px;
        border: 1px solid darkblue;
        outline: none;
        border-radius: 10px;
        width: -webkit-fill-available;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background: linear-gradient(
            90deg,
            rgba(12, 54, 115, 1) 0%,
            rgba(65, 115, 242, 1) 50%,
            rgba(12, 54, 115, 1) 100%
          );
          color: white;
        }
      }
    }
  }
}

.product__order__page {
  margin-top: 20px;
  .product__order__page__title {
    font-size: 35px;
    font-weight: bold;
    display: flex;

    .product__order__page__product__price {
      margin-left: 30px;
    }

    @media only screen and (max-width: 600px) {
      justify-content: space-between;
      font-size: 18px;
    }
  }

  .product__order__page__title__icons {
    margin-top: 10px;
    display: flex;
    align-items: center;

    .product__order__page__icon__star {
      color: #0f3979;
    }

    .product__order__page__icon__text {
      margin-left: 20px;
      color: rgb(158, 154, 154);
    }
  }

  .product__order__page__row {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @media only screen and (max-width: 999px) {
      flex-direction: column;
    }

    .product__order__page__corousel {
      width: 600px;
      margin-top: 30px;

      @media only screen and (max-width: 999px) {
        width: -webkit-fill-available;
      }

      .product__order__page__corousel__image__ramce {
        width: 500px;
        height: 400px;

        @media only screen and (max-width: 999px) {
          width: -webkit-fill-available;
          height: 540px;
        }

        @media only screen and (max-width: 600px) {
          height: auto;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

.product__order__page__user__info {
  width: 46%;

  @media only screen and (max-width: 999px) {
    width: -webkit-fill-available;
    margin-left: 0px;
    margin-top: 30px;
  }

  .product__order__page__form {
    .product__order__page__form__group {
      margin: 20px auto;

      .product__order__page__label {
        display: block;
        font-size: 17px;
        font-weight: bold;
      }

      .product__order__page__label__phone {
        margin-bottom: 10px;
      }

      .product__order__page__input__error__text {
        font-size: 15px;
        color: red;
        margin-top: 5px;
      }

      .product__order__page__input {
        padding: 10px 15px;
        margin-top: 10px;
        border: 1px solid #0f3979;
        border-radius: 8px;
        outline: none;
        width: -webkit-fill-available;
        font-size: 17px;
      }

      .product__order__page__button {
        padding: 10px 15px;
        margin-top: 10px;
        border: 1px solid #0f3979;
        border-radius: 8px;
        outline: none;
        width: -webkit-fill-available;
        font-size: 17px;
        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 30px;
        background: linear-gradient(
          90deg,
          rgba(12, 54, 115, 1) 0%,
          rgba(65, 115, 242, 1) 50%,
          rgba(12, 54, 115, 1) 100%
        );
        color: white;

        &:hover {
          box-shadow: 1px 1px 10px black;
        }

        .product__order__icon {
          margin-left: 10px;
        }
      }
    }
  }
}

.product__image__page {
  margin-top: 20px;

  .product__image__page__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 0 10px;

    @media only screen and (max-width: 780px) {
      grid-template-columns: 1fr 1fr;
    }

    .product__image__page__box {
      position: relative;
      .product__image__page__image__ramci {
        width: -webkit-fill-available;
        height: 280px;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;

        @media only screen and (max-width: 780px) {
          height: 200px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: opacity 0.5s;

          &:hover {
            opacity: 0.6;
          }
        }
      }
      .product__image__page__product__price {
        position: absolute;
        bottom: 10px;
        right: 20px;
        background: rgba(255, 255, 255, 0.6);
        padding: 2px 8px;
        border-radius: 15px;
        font-family: cursive;
        font-size: 15px;
        color: black;
      }
    }
  }
}

.product__order__saved {
  width: 100%;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .product__order__saved__image__ramce {
    width: 100px;
    height: 100px;
    margin-bottom: 50px;

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .product__order__saved__text {
    text-align: center;
  }
}

.product__order__page__description {
  @media only screen and (min-width: 900px) {
    margin-top: 50px;
  }
  .product__order__page__description__text {
    .product__order__page__description__p {
      padding: 10px;
    }
  }
}
.react-tabs__tab-panel {
  background: white !important;
}

.search__box {
  margin-bottom: 30px;
  .search__box__child {
    position: relative;
  }
  .product__search__input {
    width: -webkit-fill-available;
    padding: 10px;
    border: none;
    border-radius: 15px;
    box-shadow: 1px 1px 10px rgb(187, 185, 185);
    outline: none;
    font-size: 17px;
  }
  .search__style__right {
    position: absolute;
    width: 100px;
    height: 100%;
    background: linear-gradient(
      90deg,
      rgba(12, 54, 115, 1) 0%,
      rgba(65, 115, 242, 1) 50%,
      rgba(12, 54, 115, 1) 100%
    );
    top: 0;
    right: 0;
    border-radius: 0 15px 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
  }
}

/* 
=============
Loading
=============
*/

.product__order__icon__loading {
  width: 40%;
  height: 30px;
  background-color: aqua;
  margin-top: 10px;
}

.product__order__page__row__loading {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 999px) {
    flex-direction: column;
  }

  .product__order__page__corousel__loading {
    width: 48%;
    height: 350px;

    @media only screen and (max-width: 999px) {
      width: -webkit-fill-available;
    }
  }
  .product__order__page__form__group__loading {
    margin-top: 20px;
    width: 48%;
    overflow: hidden !important;
    position: relative;

    @media only screen and (max-width: 999px) {
      width: -webkit-fill-available;
    }
  }
}

.cart__informasion__price__seen {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.product__cart__most__seen {
  font-size: 10px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.delivery_is_paid_style {
  background: darkcyan;
  padding: 10px;
  color: #fff;
  margin-top: 15px;
  border-radius: 5px 5px 0px 0px;
}

.product__order__bonus_style {
  color: yellow;
  background: linear-gradient(90deg,
          rgba(12, 54, 115, 1) 0%,
          // rgba(65, 115, 242, 1) 50%,
          rgba(12, 54, 115, 1) 100%);
  padding: 10px;
  border-radius: 0px 0px 5px 5px;
}
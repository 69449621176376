.profile__page__links {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    width: 96%;
    margin: 0 auto;
  }

  .profile__link {
    color: white;
    display: flex;
    align-items: center;
    font-size: 15px;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    &:hover {
      color: yellow;
    }

    .profile__link__text {
      margin-left: 10px;

      @media only screen and (max-width: 600px) {
        font-size: 10px;
        margin-left: 0;
        margin-top: 5px;
      }
    }
  }
}

.grid__cart {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 30px;

  @media only screen and (max-width: 999px) {
    grid-template-columns: 1fr;
  }

  .profile__cart {
    box-shadow: 1px 1px 10px rgb(206, 203, 203);
    padding: 30px;
    border-radius: 8px;
    cursor: pointer;

    .profile__cart__row {
      display: flex;
      align-items: center;

      .profile__cart__user__fullName {
        margin-left: 20px;
        color: black;
      }

      .profile__cart__image__ramci {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: yellow;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .profile__cart__user__information {
      margin-top: 10px;

      .profile__cart__user__basic__user__information {
        color: rgb(119, 117, 117) !important;

        .profile__cart__user__basic__user__information__text {
          color: rgb(119, 117, 117) !important;
          margin-right: 20px;
        }
      }
    }
  }
}

.grid__order__information {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 80px;

  @media only screen and (max-width: 999px) {
    grid-template-columns: 1fr 1fr;
    margin-bottom: 20px;
  }

  @media only screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }

  .profile__cart__order {
    box-shadow: 1px 1px 10px rgb(168, 166, 166);
    border-radius: 8px;
    width: 100%;
    cursor: pointer;

    .profile__cart__order__body {
      padding: 30px;

      .profile__cart__order__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .profile__cart__number__of__order {
        margin-top: 15px;
      }
    }
  }
  
  .order_visits {
    background-color: #6594eb;
    color: white;
  }
  
  .order_new {
    background-color: darkgrey;
  }

  .order_accepted {
    background-color: lawngreen;
    color: black;
  }

  .order_delivered {
    background-color: seagreen;
    color: white;
  }

  .order_archieved {
    background-color: brown;
    color: white;
  }
 
  .order_temporary {
    background-color: turquoise;
    color: black;
  }

  .order_pending {
    background-color: yellow;
    color: black;
  }

  .order_cancelled {
    background-color: orangered;
    color: white;
  }

  .order_spam {
    background-color: black;
    color: white;
  }

  .order_hold {
    background-color: lightgoldenrodyellow;
    color: black;
  }

  .order_wrong {
    background-color: lightblue;
    color: black;
  }

  .order_sent {
    background-color: sandybrown;
    color: black;
  }

  .order_all {
    background-color: white;
    color: black;
  }
}

.profile__edit__page {
  width: 100%;
  box-shadow: 1px 1px 10px rgb(182, 179, 179);
  margin-top: 30px;
  border-radius: 8px;
  margin-bottom: 80px;

  @media only screen and (max-width: 780px) {
    margin-bottom: 20px;
  }

  .profile__edit__page__box {
    padding: 30px;

    .profile__edit__page__user__info {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 600px) {
        flex-direction: column;
        text-align: center;
      }

      .profile__edit__page__avatar__ramci {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: yellow;
        position: relative;

        .profile__edit__page__avatar__icon {
          position: absolute;
          width: 150px;
          height: 150px;
          background: rgba(0, 0, 0, 0.5);
          display: flex;
          justify-content: center;
          align-items: center;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .profile__edit__page__user__info__fullName {
        margin-left: 20px;
        position: relative;

        @media only screen and (max-width: 600px) {
          margin: 10px 0 0 0;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }

        .profile__edit__page__user__edit__label {
          display: flex;
          align-items: center;
          color: rgb(107, 104, 104);
          margin-bottom: 10px;

          @media only screen and (max-width: 600px) {
            margin-bottom: 5px;
          }
        }

        .profile__edit__page__user__fullName__edit {
          border: none;
          background: transparent;
          outline: none;
          font-size: 20px;
          font-weight: bold;

          @media only screen and (max-width: 600px) {
            text-align: center;
          }
        }
      }
    }
  }

  .profile__edit__page__user__informatsions {
    display: grid;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;

    @media only screen and (max-width: 780px) {
      grid-template-columns: 1fr 1fr;
    }

    @media only screen and (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .profile__edit__page__user__element__edit {
      @media only screen and (max-width: 600px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .profile__edit__page__label {
        margin-bottom: 10px;
        display: flex;
        font-size: 15px;
        color: rgb(107, 104, 104);
      }

      button {
        padding: 10px;
        background: linear-gradient(
          90deg,
          rgba(12, 54, 115, 1) 0%,
          rgba(65, 115, 242, 1) 50%,
          rgba(12, 54, 115, 1) 100%
        );
        color: white;
        cursor: pointer;
        width: 70%;
        margin-top: 10px;

        &:hover {
          box-shadow: 5px 5px 10px rgb(177, 175, 175);
        }
        border-radius: 7px;
        border: none;
        margin-top: 10px;
      }

      input {
        border: none;
        background: transparent;
        outline: none;

        @media only screen and (max-width: 600px) {
          text-align: center;
        }
      }

      select {
        border: none;
        background: transparent;
        outline: none;

        @media only screen and (max-width: 600px) {
          text-align: center;
        }
      }
    }
  }
}

.profile__edit__page__user__edit__icon {
  color: #0e3877;
  margin-left: 5px;
}

.competition__page {
  width: 100%;
  margin-top: 20px;

  .competition__image__container {
    width: 100%;
    height: 50%;
    object-fit: contain;
  }
}

.profile__cart__order_skeleton {
  box-shadow: 1px 1px 10px rgb(168, 166, 166);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;
}

.skeleton_order_status_body__parent {
    width: 100%;
    min-height: 20px;
    background-color: #d2dbe2;
    border-radius: 7px;
    position: relative;
}

.profile__cart__image__ramci_parent {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #d2dbe2;
  margin-right: 10px;
}

.profile__cart__body__row {
  display: flex;
  align-items: center;
}

.statistic_title_button {
  margin-top: 10px;
  text-align: center;
  padding: 10px;
  background-color: #0e3877;
  color: #fff;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}

.competition__page__body {
  margin-top: 20px;

  .competition__page__title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }

  .competition__page__text {
    margin: 15px 0;
    line-height: 30px;
    font-family: monospace;
    font-weight: 500;
    font-size: 15px;

    .date_time_start {
      background-color: yellow;
      color: #000;
      padding: 3px 5px;
      border-radius: 3px;
    }
    .date_time_end {
      background-color: red;
      color: #fff;
      padding: 3px 5px;
      border-radius: 3px;
    }

    .date_time_hour {
      font-weight: 600;
    }
  }

  .competition__page__gift {
    margin: 20px 0;

    .competition__page__gift__title {
      font-size: 15px;
    }

    .competition__page__gift__d {
      font-size: 14px;
      margin: 10px 0;

      .competition__page__gift__amount {
        font-weight: bold;
      }
    }
  }
}

.competition__page__footer {
  margin: 15px 0;
}

.competition__page__footer__button {
  padding: 10px 20px;
  background: #0e3877;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}

.competition__page__name_ {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 500px;
  font-size: large;
  font-weight: bold;
}
.not__found__page {
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: cursive;

  .not__found__page__image__ramci {
    width: 300px;
    height: 300px;

    img {
      width: 100%;
    }
  }
}

@keyframes ldio-8s4chfs44ut {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(18deg);
  }
  100% {
    transform: rotate(36deg);
  }
}
.ldio-8s4chfs44ut > div {
  transform-origin: 152px 152px;
  animation: ldio-8s4chfs44ut 0.1801801801801802s infinite linear;
}
.ldio-8s4chfs44ut > div div {
  position: absolute;
  width: 27.36px;
  height: 152px;
  background: #0f3a7a;
  left: 152px;
  top: 152px;
  transform: translate(-50%, -50%);
}
.ldio-8s4chfs44ut > div div:nth-child(1) {
  width: 103.36px;
  height: 103.36px;
  border-radius: 50%;
}
.ldio-8s4chfs44ut > div div:nth-child(7) {
  width: 72.96000000000001px;
  height: 72.96000000000001px;
  background: #ffffff;
  border-radius: 50%;
}
.ldio-8s4chfs44ut > div div:nth-child(3) {
  transform: translate(-50%, -50%) rotate(36deg);
}
.ldio-8s4chfs44ut > div div:nth-child(4) {
  transform: translate(-50%, -50%) rotate(72deg);
}
.ldio-8s4chfs44ut > div div:nth-child(5) {
  transform: translate(-50%, -50%) rotate(108deg);
}
.ldio-8s4chfs44ut > div div:nth-child(6) {
  transform: translate(-50%, -50%) rotate(144deg);
}
.loadingio-spinner-gear-lrufwzqo5lr {
  width: 304px;
  height: 304px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-8s4chfs44ut {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-8s4chfs44ut div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */

.loading__page {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading__text {
    font-weight: bold;
    font-size: 20px;
  }
}

.view_products_page {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;

  img {
    width: 90%;
  }

  h1 {
    margin-top: 40px;
  }
}

.footer__body {
  padding-top: 28px;
  color: white;
  padding-bottom: 10px;

  .footer__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    .footer__logo {
      @media only screen and (max-width: 780px) {
        display: none;
      }
    }

    .mobile_none {
      @media only screen and (max-width: 780px) {
        display: none;
      }
    }

    .footer__img__store__phone {
      @media only screen and (max-width: 780px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
      }
    }

    .footer__item__tetli {
      margin-bottom: 10px;
    }

    .footer__item {
      margin-bottom: 10px;
      font-weight: 400;
      color: rgb(206, 202, 202);
      cursor: pointer;
      transition: all 0.3s;

      &:hover {
        color: white;
      }
    }

    .footer__item__img {
      width: 170px;
      height: 170px;

      @media only screen and (max-width: 780px) {
        width: 140px;
        height: 100px;
      }

      img {
        width: 100%;
      }
    }

    .footer__item__icon {
      .item__icon {
        margin: 0 7px;
        cursor: pointer;
        color: rgb(206, 202, 202);
        transition: all 0.3s;

        &:hover {
          color: white;
        }
      }
    }
    @media only screen and (max-width: 780px) {
      grid-template-columns: 1fr;
      text-align: center;
    }
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

table {
  width: 100%;
}

tr {
  text-align: left;
}

.active {
  background-color: transparent !important;
  color: yellow !important;
}

.react-tel-input .flag-dropdown {
  display: none !important;
}
.react-responsive-modal-modal {
  border-radius: 3px;
  width: 335px !important;
  background: linear-gradient(
    90deg,
    rgba(12, 54, 115, 1) 0%,
    rgba(65, 115, 242, 1) 50%,
    rgba(12, 54, 115, 1) 100%
  ) !important;
}
@media only screen and (max-width: 600px) {
  .react-responsive-modal-modal {
    width: 300px !important;
  }
}
.ReactModal__Content {
  width: 86% !important;
  height: 54vh !important;
}

.modal__title {
  display: flex;
  justify-content: space-between;
  align-items: self-end;
}

/* Loading */

@keyframes ldio-k4ygfxzqhp {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(30deg);
  }
}
.ldio-k4ygfxzqhp > div {
  transform-origin: 50px 50px;
  animation: ldio-k4ygfxzqhp 0.12755102040816327s infinite linear;
}
.ldio-k4ygfxzqhp > div div {
  position: absolute;
  width: 11px;
  height: 76px;
  background: #e15b64;
  left: 50px;
  top: 50px;
  transform: translate(-50%, -50%);
}
.ldio-k4ygfxzqhp > div div:nth-child(1) {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.ldio-k4ygfxzqhp > div div:nth-child(8) {
  width: 40px;
  height: 40px;
  background: #ffffff;
  border-radius: 50%;
}
.ldio-k4ygfxzqhp > div div:nth-child(3) {
  transform: translate(-50%, -50%) rotate(30deg);
}
.ldio-k4ygfxzqhp > div div:nth-child(4) {
  transform: translate(-50%, -50%) rotate(60deg);
}
.ldio-k4ygfxzqhp > div div:nth-child(5) {
  transform: translate(-50%, -50%) rotate(90deg);
}
.ldio-k4ygfxzqhp > div div:nth-child(6) {
  transform: translate(-50%, -50%) rotate(120deg);
}
.ldio-k4ygfxzqhp > div div:nth-child(7) {
  transform: translate(-50%, -50%) rotate(150deg);
}
.loadingio-spinner-gear-hchart9hme {
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  background: none;
}
.ldio-k4ygfxzqhp {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-k4ygfxzqhp div {
  box-sizing: content-box;
}

.button__box__none__loading {
  width: 0px;
  height: 24px;
}

.carousel .thumbs {
  white-space: -webkit-nowrap;
}

.react-tel-input .form-control {
  padding: 20px 15px !important;
  margin-top: 10px !important;
  border: 1px solid #0f3979 !important;
  border-radius: 8px !important;
  outline: none !important;
  width: -webkit-fill-available !important;
  font-size: 17px !important;
}

.axis-vertical {
  display: none !important;
}

.infinite-scroll-component {
  padding: 0 7px;
}

.canvasjs-chart-credit {
  display: none !important;
}

.not__font_mu {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.policy_style {
  position: absolute;
  background-color: #ffffff;
  padding: 30px;
  font-family: emoji;
  font-weight: bold;
  line-height: 35px;
}

.stream_not_s {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid__cart__statistics {
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr 1fr;
  margin: 70px 0;

  @media only screen and (max-width: 999px) {
    grid-template-columns: 1fr;
    margin: 30px 0;
  }

  .statistics__cart {
    box-shadow: 1px 1px 10px rgb(206, 203, 203);
    padding: 30px;
    border-radius: 8px;
    cursor: pointer;

    .profile__cart__row {
      display: flex;
      align-items: center;

      .profile__cart__user__fullName {
        margin-left: 20px;
        color: black;
      }

      .profile__cart__image__ramci {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: yellow;
      }
    }

    .profile__cart__user__information {
      margin-top: 10px;

      .profile__cart__user__basic__user__information {
        color: rgb(119, 117, 117) !important;

        .profile__cart__user__basic__user__information__text {
          color: rgb(119, 117, 117) !important;
          margin-right: 20px;
        }
      }
    }
  }
}

.my_stream_grid_s {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 50px 0;

  @media only screen and (max-width: 990px) {
    grid-template-columns: 1fr;
    margin: 30px 0;
  }

  .my_stream_cart_s {
    box-shadow: 1px 1px 10px rgb(206, 203, 203);
    padding: 15px;
    border-radius: 8px;
    cursor: pointer;

    .my_stream_cart_row {
      display: flex;

      .my_stream_cart_img {
        width: 150px;
        height: 150px;
        background: linear-gradient(
          90deg,
          rgba(12, 54, 115, 1) 0%,
          rgba(65, 115, 242, 1) 50%,
          rgba(12, 54, 115, 1) 100%
        );
        border-radius: 10px;
        overflow: hidden;

        img {
          width: 150px;
          height: 150px;
          // object-fit: cover;
        }
      }

      .my_stream_cart_info {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .stream_cart_body {
          .title_left {
            font-size: 12px;
          }

          .title_ringth {
            font-size: 15px;
            font-weight: bold;
          }
        }
      }
    }

    .stream_button_s {
      width: -webkit-fill-available;
      color: white;
      font-size: 15px;
      font-weight: bold;
      margin-top: 19px;
      padding: 8px;
      border: none;
      border-radius: 8px;
      outline: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(
        90deg,
        rgba(12, 54, 115, 1) 0%,
        rgba(65, 115, 242, 1) 50%,
        rgba(12, 54, 115, 1) 100%
      );
    }
  }
}

.modal__content__stream_s {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.statistic_table_button_group {
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.statistic_table_button_group_child {
  display: flex;
  align-items: center;
  overflow-y: scroll;
  padding: 20px 0;
}
.statistic_table_button {
  margin: 0 5px;
  padding: 10px 20px;
  background:rgba(12, 54, 115, 1);
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  min-width: max-content;
}

.statistic_table_status_badge {
  padding: 3px 6px;
  border-radius: 4px;
}

#new {
  background-color: burlywood;
}

#archieved {
  background-color: cadetblue;
  color: #fff;
}

#sent {
  background-color: yellow;
}

#delivered {
  background-color: greenyellow;
}

#pending {
  background-color: yellowgreen;
}

#cancelled {
  background-color: red;
  color: #fff;
}

#accepted {
  background-color: green;
  color: #fff;
}

#spam {
  background-color: black;
  color: #fff;
}

#hold {
  background-color: lightblue;
}

.active_button {
  background-color: #fff;
  color: rgba(12, 54, 115, 1);
  border: 1px solid black;
}

.statistic_table_button_update_new {
  padding: 5px 10px;
  background-color: saddlebrown;
  margin-bottom: 5px;
  text-align: center;
  color: #fff;
  margin-top: 5px;
  border-radius: 3px;
  cursor: pointer;
}